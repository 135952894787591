.body {
  font-size: 25px;
  color: black;
  background: #f5f5f5;
}
.header-content {
  border: 1px solid black;
  justify-content: space-between;
}
.header-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  display: flex;
  width: 479px;
  height: 567px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #EFECE6;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  border-radius: unset;
}
.exit-icon {
  cursor: pointer;
  font-size: 20px;
}
.modal-title-row{
  height: 55px;
  .modal-title-wrapper{
    height: 45px;
    display: flex;
    align-items: center;
    .modal-title {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      margin-left: 20px;
    }
  }
}

.modal-image img {
  width: 8rem;
}
.modalText {
  text-align: justify;
}
.modal-button button {
  border: 1px solid #6821c3;
  padding: 6px;
  width: 8rem;
  background-color: #6821c3;
  color: white;
  border-radius: 7px;
}
.modal-mainContents{
  textarea{
    border: none;
    padding: 5px 20px;
  }
}
.modal-buttons-wrap{
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}