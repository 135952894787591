@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");
.product-assigment{
  margin-top: 30px;
  .accordion__section {
    display: flex;
    flex-direction: column;
    margin: auto !important;
    border: 1px solid #D8D8D8;
    background: #F8F5EF;
    width: auto;
    height: auto;
    flex-shrink: 0;
  }

  .design-rooms-accordion {
    color: #444;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
  }

  .design-rooms-accordion:hover,
  .active {
    background: #F8F5EF;
    border: 1px solid #D8D8D8;
  }

  .accordion__title {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding-top: 10px;
    padding-left: 10px;
  }
  .accordion__expand{
    color: #316582;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .accordion__content {
    overflow: hidden;
    transition: max-height 0.6s ease;
    border: 1px solid #D8D8D8;
    background: #F8F5EF;
  }

  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }
  .expand-icon, .expanded-icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    float: right;
  }
}
