.design-rooms{
  .design-room-info{
    margin-bottom: 15px;
  }
  .design-room-item{
    display: flex;
    align-items: center;
    justify-content: center;
    .design-rooms-accordion-block{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 143px;
      height: 88px;
      flex-shrink: 0;
      border: 0.5px solid #44444C;
      background: #FFF;
      &.active{
        border: 2px solid #316582;
        background: #FFF;
        justify-content: end;
        align-items: flex-start;
        .design-room-content{
          margin: auto 20px auto auto;
        }
      }
      .design-room-content{
        width: 66px;
        color: #44444C;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
      }
      .check-icon-wrap{
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        background: #316582;
        display: flex;
        justify-content: center;
        align-items: center;
        .check-icon{
          width: 15px;
          height: 15px;
          flex-shrink: 0;
        }
      }
    }

  }
}
