@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

.accordion__section {
  display: flex;
  flex-direction: column;
  margin: auto !important;
}

.accordion {
  border: 0.5px solid #D8D8D8;
  background: #F8F5EF;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion:hover,
.active {
  background-color: #F8F5EF;
}

.accordion__title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.accordion__expand{
  color: #316582;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
