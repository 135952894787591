.products{
  margin-top: 20px;
  margin-bottom: 20px;
  .product-info{
    .product-image{
      width: 197.727px;
      height: 227.273px;
      flex-shrink: 0;
      display: contents;
    }
    .product-wrapper{
      display: block;
    }
    .product-name{
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 136%;
    }
  }
}
