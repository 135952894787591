.feedback-section{
  height: auto;
  flex-shrink: 0;
  margin-top: 30px;
  .feedback-title-wrapper{
    display: flex;
    .feedback-title{
      color: #000;
      text-align: center;
      font-family: Basis Grotesque;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.72px;
    }
  }
  .feedback-section-hr{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 1px;
    background: #CAC4D0;
    margin-top: 30px;
  }
  .submitted-wrapper{
    .submitted-header{
      display: flex;
      padding-top: 10px;
      .title{
        padding-right: 10px;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
      }
      .date{
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
      }
    }
    .submitted-body{
      display: flex;
      padding-top: 10px;
      padding-bottom: 20px;
      text-align: justify;
    }
  }
}